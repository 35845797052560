import React from "react"

import Layout from "../components/layout"

const style = {
  margin: "100px 50px 50px 50px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}

const Takedown = () => (
  <Layout>
    <div style={style}>
      <div style={{ maxWidth: "600px" }}>
        <p>Hello,</p>

        <p>
          I am the copyright holder of all music by the artist "DJ Cutlet" and
          owner and webmaster of http://www.djcutlet.com. I would like all
          references to DJ Cutlet and http://www.djcutlet.com to be removed from
          the Internet Archive immediately.{" "}
        </p>

        <p>
          <a href="https://web.archive.org/web/*/djcutlet.com/*">Here</a> is a
          link to the list of URLs related to http://www.djcutlet.com that I
          would like to be removed from the Internet Archive. As some URLs were
          archived multiple times, I would ask that each copy of each URL on the
          list be removed.
        </p>
      </div>
    </div>
  </Layout>
)

export default Takedown
